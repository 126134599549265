import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { Brand } from "@models/models";
import _ from "lodash";

export interface SiteState {
  brand?: Brand;
  isLoading: boolean;
}

const initialState: SiteState = {
  brand: undefined,
  isLoading: false,
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setBrand(state, action) {
      state.brand = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export function getBrandThemeSettingValue(
  theme_settings: Record<string, any> | undefined,
  setting_name: string,
) {
  if (
    theme_settings &&
    (theme_settings[setting_name] || _.isBoolean(theme_settings[setting_name]))
  ) {
    return theme_settings[setting_name];
  } else {
    // @ts-ignore
    let key: keyof typeof BRAND_THEME_SETTING_KEYS = setting_name;
    return brand_theme_setting_defaults[key];
  }
}

export const selectBrandThemeSetting =
  (setting_name: string) => (state: AppState) => {
    return getBrandThemeSettingValue(
      state.site.brand?.theme_settings,
      setting_name,
    );
  };

export const selectBrandThemeText =
  (setting_name: string) => (state: AppState) => {
    return getBrandThemeSettingValue(
      state.site.brand?.theme_settings,
      setting_name,
    ).default_value.value;
  };

export const selectBrand = (state: AppState): Brand | undefined =>
  state.site.brand;
export const selectIsLoading = (state: AppState): any => state.site.isLoading;

export const { setBrand, setIsLoading } = siteSlice.actions;

export default siteSlice.reducer;

export enum BRAND_THEME_SETTING_KEYS {
  site_background_color = "site_background_color",
  menu_link_color = "menu_link_color",
  product_list_background_color = "product_list_background_color",
  product_detail_background_color = "product_detail_background_color",
  product_detail_text_color = "product_detail_text_color",
  price_box_background_color = "price_box_background_color",
  price_box_price_text_color = "price_box_price_text_color",
  price_box_title_text_color = "price_box_title_text_color",
  price_box_showtime_text_color = "price_box_showtime_text_color",
  price_box_selected_border_color = "price_box_selected_border_color",
  primary_button_background_color = "primary_button_background_color",
  primary_button_text_color = "primary_button_text_color",
  secondary_button_background_color = "secondary_button_background_color",
  secondary_button_text_color = "secondary_button_text_color",
  card_background_color = "card_background_color",
  card_text_color = "card_text_color",
  site_text_color = "site_text_color",
  site_background_image = "site_background_image",
  event_location_background_color = "event_location_background_color",
  event_location_text_color = "event_location_text_color",
  in_stock_icon_color = "in_stock_icon_color",
  stock_indicator_text_color = "stock_indicator_text_color",
  show_login_button = "show_login_button",
  show_event_location_with_thumbnail = "show_event_location_with_thumbnail",
  show_manage_pass_message_on_find_my_tickets_page = "show_manage_pass_message_on_find_my_tickets_page",
  show_see_more_events_from_venue_link = "show_see_more_events_from_venue_link",
  find_my_tickets_label = "find_my_tickets_label",
  event_showtimes_options_label = "event_showtimes_options_label",
  find_my_tickets_not_found_message = "find_my_tickets_not_found_message",
  site_favicon_image = "site_favicon_image",
}

const brand_theme_setting_defaults = {
  [BRAND_THEME_SETTING_KEYS.site_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.product_list_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.product_detail_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.product_detail_text_color]: "#111827",
  [BRAND_THEME_SETTING_KEYS.menu_link_color]: "#374151",
  [BRAND_THEME_SETTING_KEYS.card_background_color]: "#EEEEEE",
  [BRAND_THEME_SETTING_KEYS.card_text_color]: "#000000",
  [BRAND_THEME_SETTING_KEYS.site_text_color]: "#000000",
  [BRAND_THEME_SETTING_KEYS.price_box_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.price_box_price_text_color]: "#000000",
  [BRAND_THEME_SETTING_KEYS.price_box_title_text_color]: "#000000",
  [BRAND_THEME_SETTING_KEYS.price_box_showtime_text_color]: "#C2133D",
  [BRAND_THEME_SETTING_KEYS.price_box_selected_border_color]: "#7A7DF3",
  [BRAND_THEME_SETTING_KEYS.primary_button_background_color]: "#4A00FF",
  [BRAND_THEME_SETTING_KEYS.primary_button_text_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.secondary_button_background_color]: "#C61141",
  [BRAND_THEME_SETTING_KEYS.secondary_button_text_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.site_background_image]: null,
  [BRAND_THEME_SETTING_KEYS.site_favicon_image]: "/favicon.png",
  [BRAND_THEME_SETTING_KEYS.event_location_background_color]: "#C31042",
  [BRAND_THEME_SETTING_KEYS.event_location_text_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.in_stock_icon_color]: "#21C55D",
  [BRAND_THEME_SETTING_KEYS.stock_indicator_text_color]: "#6A7280",
  [BRAND_THEME_SETTING_KEYS.show_login_button]: true,
  [BRAND_THEME_SETTING_KEYS.show_event_location_with_thumbnail]: true,
  [BRAND_THEME_SETTING_KEYS.show_manage_pass_message_on_find_my_tickets_page]:
    true,
  [BRAND_THEME_SETTING_KEYS.show_see_more_events_from_venue_link]: true,
  [BRAND_THEME_SETTING_KEYS.find_my_tickets_label]:
    defaultLocalizedValue("Find My Tickets"),
  [BRAND_THEME_SETTING_KEYS.event_showtimes_options_label]:
    defaultLocalizedValue("Showtimes"),
  [BRAND_THEME_SETTING_KEYS.find_my_tickets_not_found_message]:
    defaultLocalizedValue("No tickets with that email were found."),
};

export function defaultLocalizedValue(default_value: string) {
  return {
    translated_values: [],
    default_value: {
      language: "en",
      value: default_value,
    },
  };
}
