import { ProductVariant } from "@models/models";
import _ from "lodash";
import { MoneyAmount } from "@medusajs/medusa";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

function now() {
  // @ts-ignore
  return dayjs.utc();
}
function toUtc(date: Date) {
  // @ts-ignore
  return dayjs.utc(date);
}

export default function getListViewPrice(
  variants: ProductVariant[],
): MoneyAmount {
  let active_variants = structuredClone(variants).filter(
    (v) =>
      // @ts-ignore
      !v.startsAtUtc || toUtc(v.startsAtUtc).isSameOrAfter(now()),
  );
  active_variants = active_variants.map((variant) => {
    variant.prices = variant.prices.filter((price) => {
      if (price.price_list) {
        if (price.price_list.starts_at && price.price_list.ends_at) {
          return (
            toUtc(price.price_list.starts_at).isSameOrBefore(now()) &&
            toUtc(price.price_list.ends_at).isSameOrAfter(now())
          );
        } else if (price.price_list.starts_at) {
          return toUtc(price.price_list.starts_at).isSameOrBefore(now());
        } else if (price.price_list.ends_at)
          return toUtc(price.price_list.ends_at).isSameOrAfter(now());
        else {
          return true;
        }
      } else {
        return true;
      }
    });
    return variant;
  });
  const sorted_variants = _.orderBy(
    active_variants,
    [(variant) => _.get(variant, "prices[0].amount", 0)],
    ["asc"],
  );
  if (sorted_variants.length && sorted_variants[0].prices?.length) {
    return sorted_variants[0].prices[0];
  } else {
    return { amount: 1000, currency_code: "cad" } as MoneyAmount;
  }
}
