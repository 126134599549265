import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { User } from "@medusajs/medusa";
import { Customer } from "../models/models";
import jwt from "jsonwebtoken";
export interface UserState {
  admin: User | undefined;
  customer: Customer | undefined;
}

const initialState: UserState = {
  admin: undefined,
  customer: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAdmin(state, action) {
      state.admin = action.payload;
      const api_token = localStorage.getItem("api_token");
      if (api_token) {
        const decoded = jwt.decode(api_token) as { is_spoofed: boolean };
        if (!decoded?.is_spoofed) {
          localStorage.setItem("api_token", action.payload.api_token);
        }
      } else {
        localStorage.setItem("api_token", action.payload.api_token);
      }
    },
    setCustomer(state, action) {
      state.customer = action.payload;
      localStorage.setItem(
        "customer_api_token",
        action.payload.metadata?.api_token,
      );
    },
  },
});

export const selectAdmin = (state: AppState): User | undefined =>
  state.user.admin;
export const selectCustomer = (state: AppState): Customer | undefined =>
  state.user.customer;

export const { setAdmin, setCustomer } = userSlice.actions;

export default userSlice.reducer;
