import {
  CheckIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import getStockIndicatorMessage, {
  StockIndicatorProps,
} from "@utils/stock-indicator-message";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function StockIndicator(props: StockIndicatorProps) {
  const isInStock = (props: StockIndicatorProps) => {
    return !isOutOfStock(props);
  };
  const isLowInStock = (props: StockIndicatorProps) => {
    return (
      props.inStock === undefined &&
      props.inventory_quantity !== undefined &&
      props.lowInventoryWarningThreshold !== undefined &&
      props.inventory_quantity <= props.lowInventoryWarningThreshold &&
      props.inventory_quantity > 0
    );
  };
  const isOutOfStock = (props: StockIndicatorProps) => {
    return (
      props.inStock === false ||
      (props.inventory_quantity !== undefined && props.inventory_quantity <= 0)
    );
  };
  const in_stock_icon_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.in_stock_icon_color),
  );
  const stock_indicator_text_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.stock_indicator_text_color,
    ),
  );

  if (!in_stock_icon_color || !stock_indicator_text_color) return <></>;
  return (
    <>
      {isInStock(props) && (
        <div className="mt-2 flex items-center">
          <CheckIcon
            style={{ color: in_stock_icon_color }}
            className="flex-shrink-0 w-5 h-5"
            aria-hidden="true"
          />
          <p style={{ color: stock_indicator_text_color }} className="ml-2">
            {props.in_stock_label ? props.in_stock_label : "In stock"}
          </p>
        </div>
      )}
      {isLowInStock(props) && (
        <div className="mt-6 flex items-center">
          <ExclamationCircleIcon
            className="flex-shrink-0 w-5 h-5 text-yellow-500"
            aria-hidden="true"
          />
          <p
            className="ml-2 text-sm"
            style={{ color: stock_indicator_text_color }}
          >
            {getStockIndicatorMessage(props)}
          </p>
        </div>
      )}
      {isOutOfStock(props) && (
        <div className="mt-6 flex items-center">
          <XCircleIcon
            className="flex-shrink-0 w-5 h-5 text-red-500"
            aria-hidden="true"
          />
          <p
            className="ml-2 text-sm"
            style={{ color: stock_indicator_text_color }}
          >
            Out of stock
          </p>
        </div>
      )}
    </>
  );
}
